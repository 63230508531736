<template>
    <div class="pa-3">
        <div class="pa-3 white rounded">
            <v-text-field :value="orderNum" @input="setOrderNum" placeholder="Order number" flat hide-details solo-inverted 
                v-mask="'XXX-#########'" @keydown.enter="loadOrder" ref="orderNum" @blur="onBlur"></v-text-field>
        </div>

        <v-card flat class="mt-3" :loading="loadingView">
            <v-card-text>
                <v-row v-if="order != null">
                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Order Information</div>

                        <div class="text-left">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip :color="orderStatusColor(order.status)" label class="white--text mb-3 headline" 
                                        v-bind="attrs" v-on="on">
                                        {{ order.foreignId }}
                                    </v-chip>
                                </template>
                                <span>{{ order.status }}</span>
                            </v-tooltip>
                        </div>

                        <v-simple-table>
                            <tbody>
                                <tr>
                                    <th>Status</th>
                                    <td>{{ order.status }}</td>
                                </tr>
                                <tr>
                                    <th>Pickup location</th>
                                    <td>[{{ order.pickupLocation.wintuxID}}] {{ order.pickupLocation.name }}  |  {{ order.pickupLocation.phone }}</td>
                                </tr>
                                <tr>
                                    <th>Book date</th>
                                    <td>{{ formatDate( order.orderDate, 'YYYY-MM-DD @ hh:mm a' ) }}</td>
                                </tr>
                                <tr>
                                    <th>Pickup date</th>
                                    <td>{{ formatDate( order.wantDate, 'YYYY-MM-DD @ hh:mm a' ) }}</td>
                                </tr>
                                <tr>
                                    <th>Use date</th>
                                    <td>{{ order.useDate }}</td>
                                </tr>
                                <tr>
                                    <th>Return date</th>
                                    <td>{{ order.returnDate }}</td>
                                </tr>
                                <tr>
                                    <th>Customer type</th>
                                    <td>{{ order.eventRole }}</td>
                                </tr>
                                <tr>
                                    <th>Event</th>
                                    <td>{{ order.eventId == '0' ? '' : order.eventId }}</td>
                                </tr>
                                <tr>
                                    <th>Clerk</th>
                                    <td>{{ order.employee }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>

                        <div class="subtitle-1 my-3">Comments</div>
                        <div>{{ order.comments == '' ? 'No comments available' : order.comments }}</div>
                    </v-col>

                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Customer Information</div>
                        <customer-info :customer="order.customer" :edit="false" :avatar="false" show-measurements />
                    </v-col>

                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Items</div>
                        <package-viewer :value="orderPackage" :items-assigned="order.assignedItems" itemsonly assignation />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions v-if="order != null">
                <v-spacer></v-spacer>
                <v-btn depressed color="grey darken-4" class="white--text" @click="next">Continue</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PackageViewer from '@/components/PackageViewer.vue';
import CustomerInfo from '@/components/CustomerInfo.vue';
import { mask } from 'vue-the-mask';
import moment from 'moment';

export default {
    data: () => ({
        loadingView         : false,

        assignedItems       : [],

        continueDialog      : false,
        forceDialog         : false,
        forceDialogText     : '',
        forcing             : false
    }),
    computed: {
        ...mapGetters({
            orderNum        : 'stations/spotting/orderNum',
            order           : 'stations/spotting/order',
        }),
        orderPackage() {
            if( this.order == null )
                return {};

            let m = this.order.measurements;
            let items = [];
            this.order.items.forEach(i => {
                let size = '';
                if( i.categoryId == 'C' )       size = `${m.coatSize}${m.coatLength} - ${m.coatSleeve}`;
                else if( i.categoryId == 'P' )  size = `${m.pantWaist}${m.pantType} - ${m.pantOutseam}`;
                else if( i.categoryId == 'S' )  size = `${m.shirtSize}`;
                else if( i.categoryId == 'N' )  size = `${m.tieSize}`;
                else if( i.categoryId == 'V' )  size = `${m.vestSize}`;
                else if( i.categoryId == 'SH' ) size = `${m.shoeSize}${m.shoeType}`;
                else if( i.categoryId == 'CB' ) size = `${m.cummerbundSize}`;
                else                            size = '';

                items.push({
                    ...i,
                    size
                });
            });

            return {
                items: items,
            };
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.orderNum.focus();
        });
    },
    methods: {
        ...mapActions({
            getOrder    : 'stations/spotting/loadOrder',
            clearOrder  : 'stations/spotting/clearOrder',
            setOrderNum : 'stations/spotting/setOrderNum',
        }),
        async loadOrder() {
            try {
                if( this.orderNum == '' )
                    return;

                this.playBeep2();

                this.loadingView = true;
                this.clearOrder();
                await this.getOrder();
                this.loadingView = false;
                this.setOrderNum('');
            }
            catch(error) {
                this.loadingView = false;
            }
        },
        next() {
            let needed = this.order.items.filter(i => i.hasBarcode);
            if( needed.length != this.order.assignedItems.length ) {
                this.continueDialog = true;
                return;
            }

            this.clear();
        },
        clear() {
            this.clearOrder();
            this.continueDialog = false;
            this.$refs.orderNum.focus();
        },
        formatDate( date, f = 'YYYY-MM-DD' ) {
            return moment(date).format(f);
        },
        onBlur() {
            setTimeout(() => this.$refs.orderNum.focus(), 100);
        },
    },
    components: { PackageViewer, CustomerInfo },
    directives: { mask }
}
</script>

<style lang="scss" scoped>

</style>